import React from 'react'

const AboutProjects = ({ data, theme }) => {
  console.log(data)
  // const { slug_section, title, items, language } = data

  return <div>AboutProjects</div>
}

export default AboutProjects
